/* Platform.css */
@use "sass:color";
:root {
  --primary-err-color: #ff2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #f2f2f2;
  --disable-bg-color: #606060;
  --primary-correct-color: #27ae60;
}

html[data-theme="penta"] {
  --primary-theme-color: rgba(93, 188, 210);
  --primary-theme-color-dark: rgb(0, 124, 152);
  --primary-theme-color-half: rgba(93, 188, 210, 0.5);
  --primary-theme-color-08: rgba(93, 188, 210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93, 188, 210, 0.2);
  --light-bg-color: rgba(93, 188, 210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0, 42, 132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
  --dashboard-label-bg: rgba(234, 246, 252, 0.5);
  --sidebar-light-bg: rgb(221, 244, 250);
  --light-hover-bg: rgb(221, 244, 250);
  --top-bar-offline-color: rgb(178, 193, 196);
}

html[data-theme="penguin"] {
  --primary-theme-color: rgba(35, 153, 229);
  --primary-theme-color-dark: rgb(0, 108, 180);
  --primary-theme-color-half: rgba(35, 153, 229, 0.5);
  --primary-theme-color-08: rgba(35, 153, 229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35, 153, 229, 0.2);
  --light-hover-bg: rgba(35, 153, 229, 0.1);
  --light-bg-color: rgba(35, 153, 229, 0.3);
  --dark-bg-color: rgba(35, 153, 229);
  --dark-bg-color-07: rgba(35, 153, 229, 0.7);
  --chat-bubble-color: rgba(35, 153, 229, 0.2);
  --chat-bubble-color-selected: rgba(35, 153, 229, 0.4);
  --primary-err-color: #ff4d4d;
  --primary-correct-color: #27ae60;
  --std-border: 0;
  --dashboard-label-bg: rgba(234, 246, 252, 0.5);
  --sidebar-light-bg: rgb(221, 244, 250);
  --top-bar-offline-color: rgb(178, 193, 196);
}

h1.resolve-chat {
  padding: 5%;
  text-align: center;
}

.header5 {
  font-size: 1rem;
}

.main-nurse {
  height: 100%;
}

button.ui.icon.button.tabButton {
  width: 100%;
  margin: 0;
  border-radius: 0;
  background-color: var(--sidebar-light-bg);
  color: var(--primary-theme-color);
}

button.ui.active.icon.button.tabButton {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

button.ui.icon.button.tabButton:hover:not(.active) {
  background-color: var(--primary-theme-color-half);
}

.MainScreen .ui.grid .page {
  padding: 0;
  width: calc(100% - 40px);
  height: 100%;
  /* height: calc(100vh - 40px); */
}

.MainScreen .ui.grid .page.hide-sidebar {
  width: 100%;
}

.MainScreen > .ui.grid {
  padding: 1.5px 0px 0px 0px;
  margin: 0;
  overflow: hidden;
  /* height: 93vh; */
}

.MainScreen .ui.grid.left-side-menu .column.tab {
  padding: 0;
  width: 40px;
  /* border-right: 0.5px solid var(--primary-theme-color); */
  background-color: var(--light-hover-bg);
  min-height: calc(100vh - 40px);
  max-height: 100vh;
}

.MainScreen .topbar-menu.ui.menu {
  background-color: var(--primary-theme-color);
  margin: 0;
  height: 40px;
  width: 100%;
  border-bottom: var(--std-border);

  &.offline {
    background-color: var(--top-bar-offline-color);
    & .ui.button,
    & .h2,
    & .right.item h3 {
      background-color: var(--top-bar-offline-color);
    }
  }

  & .ui.button {
    background-color: var(--primary-theme-color);
    color: var(--primary-font-color);
  }

  & .h2 {
    color: var(--primary-font-color);
    cursor: pointer;
  }

  & .right.item h3 {
    margin: 0;
    color: var(--primary-font-color);
  }
}

.DoctorScheduleView .dateHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
}

.DoctorScheduleView .content {
  padding-left: 1%;
  padding-right: 1%;
}

.DoctorScheduleView .calendarContent {
  height: 77vh;
  max-height: 77vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: solid var(--primary-theme-color) 1px;
}

.DoctorScheduleView .calendarContent::-webkit-scrollbar {
  /* width: 0px; */
  /* background: transparent;  */
  background: var(--primary-theme-color);
  opacity: 0.2;
  /* background: rgba(93,188,210, 0.2); */
}

.DoctorScheduleView .calendarContent::-webkit-scrollbar-thumb {
  /* background: rgba(93,188,210, 0.8); */
  background: var(--primary-theme-color);
  opacity: 0.8;
}

.DoctorScheduleView .dateHeader .button {
  background-color: var(--primary-theme-color);
  opacity: 0.5;
}

.mod-select-appointment.ui.modal .header {
  display: flex;
  justify-content: space-between;
}

.FormAnalytics .triage-level-list {
  background-color: var(--light-bg-color);
  height: 92px;
  width: 117px;
  border-radius: 10px;
  padding: 16px;
  color: gray;
  cursor: pointer;
}

.FormAnalytics .triage-level-list:hover {
  background-color: var(--primary-theme-color-half);
}

.FormAnalytics .triage-level-list.selected {
  background-color: var(--primary-theme-color);
}

.FormAnalytics .headers {
  background-color: var(--primary-theme-color);
  color: white;
}

.FormAnalytics .items-list {
  padding-top: 0px !important;
  padding-right: 0px !important;
  height: 80vh;
  overflow: auto;
}

.FormAnalytics .items-list .ui.form:hover {
  background-color: var(--primary-theme-color-half);
  opacity: 0.75;
  cursor: pointer;
}

.FormAnalytics .items-list .ui.form.selected {
  background-color: var(--primary-theme-color-half);
}

.FormAnalytics .assessment-form {
  background-color: var(--light-bg-color);
  height: 80vh;
  overflow: auto;
  padding: 0 !important;
}

.FormAnalytics .assessment-form .answer {
  padding: 10px;
  background: white;
  border-radius: 5px;
  margin-bottom: 3px;
  padding-top: 5px;
}

.FormAnalytics .primary {
  color: var(--primary-theme-color);
}

.register-new-user {
  display: flex;
  height: 100%;
  margin-top: 20px;

  .profile-image-section {
    height: 100%;
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile-image {
      width: 120px;
      margin: 20px 0px;
    }
    .profile-button {
      margin: 20px 0px;
    }
    .profile-text {
      color: #4f4f4f;
    }
  }

  .profile-info {
    flex-basis: 80%;
    padding: 0px 100px 0px 44px;
    margin-bottom: 250px;
    border-left: 0.5px solid #b6b6b6;

    label.reg {
      width: 180px !important;
      color: #828282 !important;
      font-size: 15px !important;
    }
    .reg {
      width: 400px !important;
    }
    .last-div {
      display: flex;
      // justify-content: flex-end;
      padding-left: 500px;
    }
  }
}

.no-oa-channel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .title-first {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .title-second {
    font-size: 24px;
    font-weight: bold;
  }

  .last-div {
    margin-top: 100px;
  }
}

// OA Form
.oa-form-custom {
  .ui.button {
    &.primary {
      background-color: var(--primary-theme-color);
      width: 150px;
      color: white;
      border-radius: 6px;
    }
    &.second {
      background-color: #48a1d9;
      border-radius: 8px;
      padding: 13px 55px;
    }
    &.back {
      background-color: #f2994a;
    }
  }

  .primary {
    color: var(--primary-theme-color);
    &.ui.input > input {
      color: var(--primary-theme-color);
    }
  }

  .ui.input.error > input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
  }

  .nav-bar {
    background-color: var(--light-bg-color);
    color: var(--primary-theme-color);
    .ui.items {
      .item {
        padding: 14px 0 14px 20px;
        margin: 0;
        font-weight: bold;
        cursor: pointer;
        i {
          margin-right: 10px;
        }
        &.is_active {
          background-color: var(--primary-theme-color);
          color: white;
        }
      }
    }
  }
  .font-error {
    display: flex;
    justify-content: flex-end;
    color: var(--primary-err-color);
  }
}

// Step-Progress
.steps.oa {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: color.adjust(#e6e6e6, $lightness: -33%);
  height: 4rem;

  > .step {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 1rem;
    color: #dce1e4;
    font-weight: bold;

    &:before {
      content: attr(data-step);
      display: block;
      margin: 0 auto;
      background: #e7eaf3;
      border: 2px solid #e6e6e6;
      color: #b6bbbf;
      width: 3.2rem;
      height: 3.2rem;
      text-align: center;
      line-height: 2.7rem;
      border-radius: 100%;
      position: relative;
      z-index: 1;
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 0.6rem;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 0.125rem;
      top: 1.5rem;
      left: 50%;
      border-top: 2.3px dashed #e7eaf3;
    }
    &:last-child:after {
      display: none;
    }
    &.is-complete {
      color: #5a5a5a;

      &:before {
        // content: "\2713";
        color: var(--primary-theme-color);
        // background: var(--primary-theme-color-half);
        border: 2px solid var(--primary-theme-color);
        transition: 0.6s ease-out;
        background: white;
      }
      &:after {
        // background: var(--primary-theme-color);
        border-top: 2px dashed var(--primary-theme-color);
        transition: 1s ease-out;
      }
    }
    &.is-active {
      font-size: 1.15rem;
      color: #131313;
      &:before {
        color: #fff;
        border: 2px solid var(--primary-theme-color);
        background: var(--primary-theme-color);
        margin-bottom: 0.6rem;
        transition: 1s ease-out;
      }
    }
  }
}

// Notification
.card-noti-oa {
  position: absolute;
  border-radius: 13px;
  padding: 26px;
  background-color: white;
  right: 3.4em;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.15);
  width: 400px;
  top: 2.4em;
  .btn-remove {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: -10px;
    color: var(--primary-theme-color);
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #434040;
  }
}

.ui.menu .ui.dropdown .menu > #create_oa {
  color: var(--primary-theme-color) !important;
}

// SettingOA
.setting-oa {
  .ui.grid .tab {
    background-color: white;
  }
  .profile-image {
    margin-top: 0px;
  }
  .ui.form .field {
    margin-bottom: 15px;
  }

  .agency-code {
    div {
      display: flex;
      align-items: baseline;
      .ui.input {
        width: 287px !important;
        margin-left: 13px;
        margin-right: 25px;
      }
    }
  }

  .ui.button.btn-green {
    border: 1px solid #27ae60;
    border-radius: 9px;
    cursor: pointer;
    color: #27ae60;
    background-color: white;
    margin-left: 15px;
  }

  .ReactTable .-odd {
    background-color: white;
  }
  .filter-input {
    .ui.input input {
      border-color: var(--primary-theme-color);
    }
    .ui.dropdown {
      border-color: var(--primary-theme-color);
    }
  }
}

.setting-oa,
.main-doctor-schedule {
  .ui.menu.fluid,
  .ui.vertical.menu {
    &.fluid {
      width: 120% !important;
      border-radius: 0;
      box-shadow: none;
    }
    .item {
      padding: 15px;
      font-size: 14px;
      border-radius: 0;
      flex-direction: initial;
      display: flex;
      i {
        margin-right: 10px;
      }
    }
    .active.item {
      background: rgba(31, 120, 180, 0.8);
      color: white;
      border-radius: 0;
    }
  }
  .ui.grid .tab {
    background-color: white;
  }
}

// Studio {
.main-studio {
  .list-create {
    // overflow: auto;
    margin-top: -10px;
    transform: scale(0.9);
    .group-item {
      height: 94vh;
      overflow-y: auto;
      padding-bottom: 10px;
    }
    .header-list {
      background-color: var(--primary-theme-color);
      color: white;
      padding: 8px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ui.circular.button {
        background-color: white;
        i {
          color: var(--primary-theme-color);
        }
      }
    }
  }
}

// Doctor Schedule
.main-doctor-schedule {
  .list-create {
    // overflow: auto;
    margin-top: -10px;
    transform: scale(0.9);
    .group-item {
      height: 94vh;
      overflow-y: auto;
      padding-bottom: 10px;
    }
    .header-list {
      background-color: var(--primary-theme-color);
      color: white;
      padding: 8px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ui.circular.button {
        background-color: white;
        i {
          color: var(--primary-theme-color);
        }
      }
    }
    .item {
      background-color: white;
      padding: 15px 10px;
      border-bottom: 1px solid #bdbdbd;
      cursor: pointer;
      // color: #4f4f4f;
      .primary {
        color: var(--primary-theme-color);
        &.number {
          margin: 0 5px;
        }
      }
      &.active {
        background-color: var(--light-bg-color);
      }
    }
  }
  .ui.form.table-management {
    .label {
      width: 125px;
    }
  }

  .ui.button {
    &.primary {
      background-color: var(--primary-theme-color);
      color: white;
    }
    &.grey {
      background: white;
      border: 1px solid #e0e0e0;
      color: #e0e0e0;
    }
    &.light {
      background-color: var(--light-bg-color);
      color: white;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .font-primary {
    color: var(--primary-theme-color);
  }

  .list-date {
    display: flex;
    .group-item {
      display: flex;
      align-items: flex-end;
      margin-left: 10px;
      .item {
        display: grid;
        margin-left: 7px;
        margin-right: 2px;
        color: #828282;
        div:nth-child(1) {
          font-weight: bold;
        }
      }
      .ui.checkbox label:before {
        border: 1px solid var(--primary-theme-color);
      }
    }
  }
}

.time-custom-react-picker {
  width: 99px;
  background-color: white;
  & + div {
    margin: auto 0 !important;
  }
  .react-time-picker__wrapper {
    border-radius: 0.28571429rem;
    height: 37.8px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    overflow: hidden;
    .react-time-picker__inputGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      .react-time-picker__inputGroup__input {
        width: 10px;
        height: 17px;
        border: 0;
      }
      .react-time-picker__inputGroup__divider {
        color: var(--primary-theme-color);
      }
    }
    .react-time-picker__clear-button {
      display: none;
    }
  }
  &.padding-input {
    .react-time-picker__wrapper
      .react-time-picker__inputGroup
      .react-time-picker__inputGroup__leadingZero {
      padding-left: 13px;
      padding-top: 0.1em;
    }
    .react-time-picker__wrapper
      .react-time-picker__inputGroup
      .react-time-picker__inputGroup__input--hasLeadingZero {
      width: 9px;
      padding-left: 1px;
      padding-right: 13px;
      padding-top: 0.7em;
    }
  }
  &.padding-divider {
    .react-time-picker__inputGroup__divider {
      margin: 0 10px;
    }
  }
}

// QAChat

.qa-chat-table,
.qa-chat-modal {
  .font-primary {
    color: var(--primary-theme-color);
  }
  .font-blue,
  .ui.input.font-blue input {
    color: #2f80ed;
  }
  .ui.button.btn-primary {
    background-color: var(--primary-theme-color) !important;
    color: white;
  }
}
.qa-chat-table {
  // padding: 10px;

  * {
    scrollbar-width: thin;
    &:-webkit-scrollbar {
      width: 6px;
    }
    /* webkit browsers */
    &::-webkit-scrollbar {
      width: 6px;
    }
  }

  .ui.button {
    &.primary {
      background-color: var(--primary-theme-color);
      width: 150px;
      color: white;
      border-radius: 6px;
    }

    &.back {
      background-color: #f2994a;
    }
  }

  .profile-form {
    padding: 5px;
    overflow-y: auto;
    height: calc(100vh - 82px);
    .header {
      padding: 5px;
      margin-bottom: -10px;
      // margin-top: 10px;
      margin-left: 5px;
    }
    .inline.field {
      display: flex;
      align-items: center;
    }
  }
  .center-header {
    display: grid;
    grid-template-columns: 25% 75%;
    background: #f2f2f2;
    padding: 2px 10px 1px;
    .ui.button {
      padding: 7px 20px;
      border-radius: 8px;
      margin: 3px 4px;
      margin-bottom: 4px;
      border: 2px solid var(--primary-theme-color);
      border-width: 1px !important;
      &.finish-treatment {
        color: #eb5757;
        border: 2px solid #eb5757;
        background-color: white;
      }
      &.edit-appointment,
      &.create-appointment {
        background-color: white !important;
      }
      &.teleconsult {
        border: 2px solid #27ae60;
        background-color: #27ae60;
      }

    }
    .ui.dropdown {
      padding: 7px 20px;
      border-radius: 8px;
      margin: 3px 4px;
      margin-bottom: 4px;
      min-height: auto !important;
      height: 2.1em;
      width: 100%;
      .text {
        overflow: hidden;
        height: 17px;
        line-height: 20px;
        margin-top: -3px;
      }
      i {
        margin-top: -13px !important;
      }
    }
  }
  .form-label-primary {
    &.ui.form .field > label {
      color: var(--primary-theme-color);
      font-weight: bold;
    }
  }
  .card-patient-panel {
    .sub.header {
      margin-bottom: 30px;
      &.left-align {
        margin: 5px;
        line-height: 2.2em;
      }
    }
  }
  .card-vital-sign {
    .ReactTable .rt-thead {
      flex: unset;
    }
    .rthfc .rt-th,
    .rthfc.-sp .rthfc-th-fixed {
      background-color: unset;
    }
  }
  .badge {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
  }
}
.ui.modal {
  .modal-profile {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border-radius: 50%;
        width: 60%;
      }
    }
    .detail {
      div {
        line-height: 2.2em;
        font-weight: bold;
      }
    }
  }
  .modal-profile-button {
    .ui.button {
      &.primary {
        background-color: var(--primary-theme-color);
        width: 150px;
        color: white;
        border-radius: 6px;
      }

      &.back {
        background-color: #f2994a;
      }
    }
  }
}

.chat-classify-list {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 175px);

  // grid-template-columns: 115px auto;
  .left-list {
    // height: 79vh;
    overflow-y: auto;
    padding-bottom: 0.5em;
    padding-top: 2px;
    padding-right: 3px;
  }

  .classify-group {
    width: 195px;
    min-height: 65px;
    border-radius: 8px;
    background: var(--primary-theme-color);
    padding: 8px;
    margin-bottom: 5px;
    color: white;
    font-weight: "bold";
    cursor: pointer;
    transition: width 0.3s;
    // margin-right: 85px;
    &.is_active {
      background: #0072bc;
      .icon-overlay {
        color: #0072bc !important;
      }
    }
    .content {
      width: 85%;
      // height: 38px;
      overflow: hidden;
      font-weight: bold;
    }
    .icon-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      transform: scale(0.8);
      color: var(--primary-theme-color) !important;
    }
  }
  .items {
    padding: 0 3px 8px 3px;
    width: 100%;
    transition: 0.3s ease-out;
    overflow-y: auto;
    padding-bottom: 0.9em;

    .item {
      padding: 10px 10px 20px 5px;
      border-radius: 3px;
      cursor: pointer;
      border-bottom: 1px solid #f2f2f2;
      &.active {
        background-color: rgba(114, 246, 189, 0.3) !important;
      }
      &:hover {
        background-color: rgba(135, 152, 145, 0.08);
      }
      div {
        margin-bottom: 5px;
      }
      label {
        font-weight: bold;
        color: black;
      }
    }
  }

  &.full-screen {
    .classify-group {
      width: 0;
      display: none;
    }
    .classify-group:nth-child(1) {
      height: 37px;
      min-height: auto;
      display: block;
      position: absolute;
      width: 92%;
      background-color: var(--primary-theme-color);
      .content {
        width: auto;
        display: flex;
        height: 17px;
      }
    }
    .items {
      margin-top: 47px;
      padding-left: 0;
    }
  }
}

.menu-patient-info {
  width: 50px;
  border-right: 1px solid #bdbdbd;
  height: calc(100vh - 82px);
  .ui.button {
    width: 100%;
    margin: 0;
    border-radius: 0;
    // background-color: var(--sidebar-light-bg);
    background-color: white;
    // color: var(--primary-theme-color);
    color: #4f4f4f;
    padding: 15px;
    i {
      font-size: 17px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &.active {
      background-color: var(--sidebar-light-bg);
    }
  }
}

// ----- Dental Diagram
// hover normal teeth
#diagram div.normal svg {
  circle:hover,
  path:hover,
  polygon:hover {
    fill: green;
  }
}

// hover super teeth
#diagram div.choosesuper svg {
  circle[id*="c"]:hover {
    fill: green;
  }
}

// hover missing teeth
#diagram div.choosemissing svg {
  cursor: pointer;
}

.intra-telephar-calling {
  position: relative;
  padding-top: 3px;

  img {
    width: 15px;
    position: absolute;
    animation: calling-loop 2s infinite;
    left: 5px;

    &:nth-of-type(1) {
      animation-delay: 0s;
    }
    &:nth-of-type(2) {
      animation-delay: 1s;
    }
  }
}

@keyframes calling-loop {
  0% {
    visibility: visible;
  }
  25% {
    visibility: visible;
  }
  50% {
    visibility: visible;
  }
  75% {
    visibility: visible;
  }
  100% {
    visibility: visible;
  }
}

// HISV3
$card-min-width-s: 720px;
$card-min-width-m: 820px;
$card-min-width-l: 920px;

@mixin or-request {
  #CardORRequest {
    min-width: $card-min-width-l;
  }
  #CardSelectDateTimeOperation {
    min-width: $card-min-width-s;
  }
  #CardOtherOrderAppointment {
    #CardDrugOrder,
    #CardPreOrder,
    .ui.divider,
    .appointment-button-action {
      min-width: $card-min-width-m;
    }
    #CardPreOrder .ui.vertical.menu {
      width: 11rem;
    }
  }
}

@mixin drug-order-working {
  &.show-toolbox .ReactTable {
    width: 74% !important;
    ~ div {
      width: 26% !important;
    }
  }

  #cb-deliver {
    width: 120px !important;
  }

  #deliver-employee-token > .input,
  #deliver-others {
    width: 0 !important;
  }

  .button {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  #deliver-others {
    min-width: 7rem;
  }

  #deliver-employee-token .input {
    min-width: 7rem;
  }
}

@mixin anesthetic-document {
  .anesthetic-document-tabs,
  .pre-anesthetic-tabs,
  .pacu-record-tabs {
    min-width: $card-min-width-l;
    .button {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    ~ hr {
      min-width: $card-min-width-l;
    }
  }

  #CardPreAnestheticHistory,
  #CardPreAnestheticAirwayEvaluation,
  #CardPreAnestheticBloodChemistry,
  #CardPreAnestheticRisk,
  #CardPreAnestheticProblemsConsiderations,
  #CardPreAnestheticReEvaluation,
  #CardPACURecordAnesthesia,
  #CardPACURecordIntraoperative,
  #CardPostAnestheticRecoveryScore,
  #CardPACURecordMonitor,
  #CardPACURecordProblems,
  #CardPACURecordReferral,
  #CardPeriDataSheetForm,
  #CardAnesBillTab,
  #CardAnesBillTable,
  #CardDischargeSummary {
    min-width: $card-min-width-l;
  }

  #CardPreAnestheticForm {
    > div {
      min-width: $card-min-width-l;
    }
  }

  #CardPACURecordForm {
    min-width: $card-min-width-l;
    .ui.form {
      > .fields > .field:nth-of-type(1) label {
        min-width: 70px !important;
        max-width: 70px !important;
      }
    }
  }

  #CardAnesComplicationTab {
    min-width: $card-min-width-l;

    .anes-complication-tabs .button {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  #CardAnesComplication {
    min-width: $card-min-width-l;
    .choices .ui.checkbox {
      margin-right: 1rem !important;
    }
  }

  #CardAnesRecord01 {
    min-width: $card-min-width-l;

    .ui.form {
      .fields .field.wide {
        &:nth-of-type(1) label {
          min-width: 70px !important;
        }

        &:nth-of-type(2) label {
          min-width: 90px !important;
        }

        &:nth-of-type(3) label {
          min-width: 60px !important;
        }
      }

      .time-custom-react-picker {
        width: 70px;
        & .react-time-picker__inputGroup__input {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }

  #CardAnesRecord02 {
    min-width: $card-min-width-l;

    .ui.form {
      > .fields > .field:not(.wide):nth-of-type(1) {
        min-width: 130px !important;
        max-width: 130px !important;
      }

      > div:not(.fields) > .fields > .field:not(.wide):nth-of-type(1) {
        min-width: 130px !important;
        max-width: 130px !important;
      }
    }
  }
}

@mixin perioperative-nursing-record {
  #CardPerioperativeNursingRecord {
    min-width: $card-min-width-l;
  }

  // Basic Info
  #CardBasicInfo,
  // Step A-H
  #CardSurgicalPosition,
  #CardTourniquet,
  #CardElectricalXrayUltrasound,
  #CardDefibrillator,
  #CardLaser,
  // Other Form
  #CardSpecialEquipment,
  #CardEssentialImaging,
  #CardSkinPreparation,
  #CardIrrigation,
  #CardMedication,
  #CardByEquipment,
  #CardPostopeartiveNursingRecord {
    min-width: $card-min-width-l;
    padding: 15px 10px 15px 15px !important;
    margin: 10px 5px !important;
  }

  // Basic Info
  #CardAddSurgeryTeam,
  #CardAnesthesiaTeam {
    padding: 1rem 0.25rem !important;
    .ui.form {
      > .fields:nth-of-type(1) .button {
        padding-left: 0.35rem !important;
        padding-right: 0.35rem !important;
      }
    }
  }

  // By Equipment
  #CardByEquipment #CardTube {
    .ui.form > .fields > div:nth-of-type(1) {
      label {
        min-width: 110px !important;
        max-width: 110px !important;
      }
    }
  }
}

@mixin pre-operation {
  .pre-operation-tabs {
    min-width: $card-min-width-m;
    ~ hr {
      min-width: $card-min-width-l;
    }
  }

  #CardPreOPVisit,
  #CardORRegistrationArea {
    min-width: $card-min-width-m;
    padding: 15px 10px 15px 15px !important;
    margin: 10px 5px !important;
  }

  #CardHoldingRoom {
    min-width: $card-min-width-m;
    #title-procedure-confirm {
      div:nth-of-type(2) {
        margin-left: 0 !important;
      }
      div:nth-of-type(3) {
        margin-right: 8rem;
      }
    }
    .ui.form {
      > .fields > .field:not(.wide):nth-of-type(1) label {
        min-width: 135px !important;
        max-width: 135px !important;
        &.subtitle-indent {
          padding-left: 10px !important;
        }
      }
    }
  }
}

@mixin surgical-safety-checklist {
  #CardSurgicalSafetyChecklist {
    min-width: $card-min-width-m;
    > div {
      padding: 15px 10px 15px 15px !important;
      margin: 10px 5px !important;
    }
  }
}

@mixin counts-packing {
  .counts-packing-tabs {
    ~ hr {
      min-width: $card-min-width-m;
    }
  }
  #CardCounts,
  #CardPacking {
    min-width: $card-min-width-m;
  }
}

.main-nurse .MainScreen {
  .tab-menu.--framework {
    max-height: calc(100dvh - 60px) !important;
    > div:last-of-type {
      margin-bottom: 5px;
    }
  }
}

@media only screen and (orientation: landscape) and (max-width: 1367px) {
  .main-nurse .MainScreen {
    > div:nth-of-type(2) {
      height: calc(100dvh - 40px) !important;
    }

    .ui.dropdown.selection > .text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    #CardADM {
      width: calc(100vw - 60px) !important;
      overflow-x: hidden !important;
      padding: 10px 0 0 10px !important;
      #CardReserveTransferQueue,
      #CardReserveBedQueue {
        overflow: auto;
        padding-left: 0 !important;
        > div {
          min-width: 77rem;
        }
      }
    }

    #CardPatientAppointment {
      .patient-appointment-panel {
        max-width: 370px;
        .patient-appointment-menus > .button {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
      .patient-appointment-content {
        width: 0;
        height: 95dvh !important;
        .patient-appointment-tabs {
          width: max-content;
        }

        @include or-request;
      }
    }

    #CardReceiveOrder #CardDrugOrderWorking {
      @include drug-order-working;
    }

    #CardPharmacyTask {
      width: calc(100vw - 60px) !important;
      .pharmacy-task-tabs > .button {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    #CardPatientList {
      width: calc(100vw - 60px) !important;
      height: calc(100dvh - 30px) !important;

      #CardWardQueue {
        min-width: 940px;
        .ui.form .fields:nth-of-type(3) .button {
          padding-left: 0.75em;
          padding-right: 0.75rem;
        }
      }

      .tab-menu.--framework {
        max-height: calc(100dvh - 90px) !important;
        > div:last-of-type {
          margin-bottom: 15px;
        }
        ~ div {
          max-height: calc(100dvh - 90px) !important;

          #CardORCaseList .button,
          #CardORCancelList .button,
          #CardORQueue .button,
          #CardAnesDoctorFeeList .button {
            padding-left: 0.75em;
            padding-right: 0.75rem;
          }
        }
      }
    }

    #CardAppointmentList {
      width: calc(100vw - 60px) !important;
      height: calc(100dvh - 30px) !important;
    }

    #CardSupplyOrderQueueList {
      .ReactTable {
        height: calc(100dvh - 20rem) !important;
      }
    }

    #CardSupplyDeliveryHistory {
      .ReactTable {
        height: calc(100dvh - 30rem) !important;
      }
    }

    .tab-menu.--framework {
      ~ div {
        max-height: calc(100dvh - 60px) !important;
        #CardImagingOrder {
          min-width: $card-min-width-m;
          .label-suspected-diagnosis {
            width: 70px !important;
          }

          .search.dropdown[name="order_div"] {
            min-width: 12rem !important;
          }

          button[name="CONFIRM_ORDER"] {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
          }
        }

        #CardImagingResult {
          min-width: $card-min-width-m;
          & .label-suspected-diagnosis {
            width: 90px !important;
          }
        }

        #CardTreatmentResult,
        #CardDrugOrderQueue,
        #CardSupplyOrderWorkflow,
        #ContinueMed,
        #CardDrugOrder {
          min-width: $card-min-width-m;
        }

        #CardMedCert {
          .column > .row .button {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
          }
        }

        #CardDrugOrderWorking {
          min-width: $card-min-width-m;

          @include drug-order-working;
        }

        @include or-request;

        @include perioperative-nursing-record;

        @include anesthetic-document;

        @include pre-operation;

        @include surgical-safety-checklist;

        @include counts-packing;

        #CardPostOperation {
          min-width: $card-min-width-m;
          padding: 15px 10px 15px 15px !important;
          margin: 10px 5px !important;
        }

        #CardVerbalOrder {
          min-width: $card-min-width-m;
          .tabs-verbal-order {
            > .button {
              margin-left: 5px !important;
            }
          }
          .save-verbal-order {
            #SearchBoxDropdown-Doctor {
              width: 225px !important;
            }
            .ui.dropdown {
              min-width: 12rem;
            }
          }
        }

        #CardMedReconcile,
        #CardSupplyOrder {
          min-width: $card-min-width-m;
          .search-order-history {
            .ui.checkbox {
              min-width: 90px !important;
            }
          }
        }

        #CardAdmitOrder {
          min-width: $card-min-width-m;
          .middle.aligned.row {
            flex-wrap: nowrap;

            .column:not(.right) {
              padding-right: 0;

              .teal.right.button {
                margin-right: -22px;
              }
            }
          }
        }

        #SubAddress {
          .ui.form {
            > .fields > .field.six label {
              min-width: 75px !important;
              max-width: 75px !important;
            }
            > .fields > .field.six:nth-of-type(1) label,
            .fields > .field.four:nth-of-type(1) label {
              min-width: 60px !important;
              max-width: 60px !important;
            }
          }
        }

        #CardLabPanel {
          min-width: $card-min-width-m;

          .lab-order-detail {
            .mini.button {
              margin-left: -0.5rem;
            }

            .ui.label {
              min-width: 87.5px !important;
            }

            .field-label {
              padding-right: 0.15rem;
              margin-left: -0.75rem;
            }
          }
        }
      }
    }
  }
}

.datetime-text-box {
  display: flex;
  width: fit-content;
  position: relative;
  .DayPickerInput .ui.input input {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &:hover {
      border-color: rgba(34, 36, 38, 0.35);
      box-shadow: none;
    }
  }
  .separator {
    position: absolute;
    margin-left: 96px;
    z-index: 999;
    top: 48%;
    font-size: 1.25rem;
    transform: translate(-50%, -50%);
  }
  .DropdownOptions {
    height: 100%;
    .ui.dropdown {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: none;
      min-height: 100%;
    }
  }
  .right-icon {
    opacity: 0.8;
    position: absolute;
    margin-left: 161px;
    z-index: 999;
    top: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
    .time.icon {
      font-size: 0.515em;
    }
  }
}

#CardDrugOrderQueue {
  .clickable-text {
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 1px;
    font-weight: bold;
  }

  .clickable-text:hover {
    border-bottom: 1.5px solid #a7a7a7;
  }
}
