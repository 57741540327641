.card-patient-panel {
  .Img-container {
    position: relative;
    &:hover {
      & .middle {
        display: block;
      }
      & .image {
        opacity: 0.5;
      }
    }
    .image {
      object-fit: cover;
      max-width: 200px;
      height: 200px;
      border: solid black 1px;
      margin-right: 10px;
      width: 100%;
      opacity: 1;
    }
    .label-tag-image {
      position: absolute;
      right: -10px;
      top: -1px;
    }
    .middle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      .InputUpload {
        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
        border-radius: 500rem;
        padding: 7px 2px 7px 5px;
        cursor: pointer;
      }
    }
  }
}
