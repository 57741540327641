.scoreGood {
  .ui.input input {
    background-color: #bbeaa1;
    border: 1px solid #44a110;
    text-align: center;
    width: 100%;
  }
}

.scoreBad {
  .ui.input input {
    background-color: #ffb0b0;
    border: 1px solid #f14d4d;
    text-align: center;
    width: 100%;
  }
}

.fixDropDown {
  & .ui.selection.visible.dropdown>.text:not(.default) {
    height: 30px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & .ui.fluid.search.selection.dropdown {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & .ui.search.dropdown>.text {
    white-space: nowrap;
    max-width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
  }
  
}
